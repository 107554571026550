<template>
  <div>
    <allProduct :genre="0"></allProduct>
  </div>
</template>

<script>
import allProduct from "@/components/allProduct";
export default {
  components: { allProduct },
  name: "",
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped></style>
